import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gettokenreserves",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#gettokenreserves",
        "aria-label": "gettokenreserves permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getTokenReserves`}</h1>
    <p>{`This function fetches Uniswap reserve data for a given token address on a given network.`}</p>
    <ul>
      <li parentName="ul">{`If only a chain id is specified, the Ethereum node used to fulfill data requests is determined by `}<a parentName="li" {...{
          "href": "https://docs.ethers.io/ethers.js/html/api-providers.html#connecting-to-ethereum"
        }}><inlineCode parentName="a">{`ethers.getDefaultProvider`}</inlineCode></a>{`, else it is the one specified by the passed provider.`}</li>
      <li parentName="ul">{`This function throws an error if the provided tokenAddress is not a token with a Uniswap exchange.`}</li>
    </ul>
    <h2 {...{
      "id": "function-signature",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#function-signature",
        "aria-label": "function signature permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Function Signature`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export async function getTokenReserves(
  tokenAddress: string,
  chainIdOrProvider: ChainIdOrProvider = 1
): Promise<TokenReservesNormalized>
`}</code></pre>
    <h2 {...{
      "id": "input-parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#input-parameters",
        "aria-label": "input parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Input Parameters`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tokenAddress`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The checksummed address of a token with a Uniswap exchange.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`chainIdOrProvider`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ChainIdOrProvider`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A supported chain id `}{`(`}<inlineCode parentName="td">{`1`}</inlineCode>{`, `}<inlineCode parentName="td">{`3`}</inlineCode>{`, `}<inlineCode parentName="td">{`4`}</inlineCode>{`, or `}<inlineCode parentName="td">{`42`}</inlineCode>{`)`}{`, or an `}<a parentName="td" {...{
              "href": "https://docs.ethers.io/ethers.js/html/api-providers.html#web3provider-inherits-from-jsonrpcprovider"
            }}>{`underlying web3 provider`}</a>{` connected to a chain with a supported chain id.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "example-usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#example-usage",
        "aria-label": "example usage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const tokenAddress = '0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359' // DAI Mainnet
const chainIdOrProvider: ChainIdOrProvider = 1 // could be e.g. window.ethereum instead

const tokenReserves: TokenReservesNormalized = await getTokenReserves(tokenAddress, chainIdOrProvider)

/*
{
  // details for the passed token
  token: {
    chainId: 1,
    address: '0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359',
    decimals: 18
  },

  // details for the Uniswap exchange of the passed token
  exchange: {
    chainId: 1,
    address: '0x09cabEC1eAd1c0Ba254B09efb3EE13841712bE14',
    decimals: 18
  },

  // details for the ETH portion of the reserves of the passed token
  ethReserve: {
    token: {
      chainId: 1,
      address: 'ETH',
      decimals: 18
    },
    amount: <BigNumber>
  },

  // details for the token portion of the reserves of the passed token
  tokenReserve: {
    token: {
      chainId: 1,
      address: '0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359',
      decimals: 18
    },
    amount: <BigNumber>
  }
}
*/
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      